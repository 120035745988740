import React, { useEffect, useState } from "react";
import { Spinner, FormGroup } from "react-bootstrap";
import petitionPost from "../../../../services/petitionPost";
import { sendToastError, sendToastSuccess } from "../../../../utils/toastAlert";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { useForm } from "react-hook-form";
import { ToastContainer } from "react-toastify";

function Orders({ profile, balance, setStep, thereIsCreditCard }) {
  let { account } = profile.auth.auth;
  const [currentBalance, setCurrentBalance] = useState(true);
  const [checkCurrentBalance, setcheckCurrentBalance] = useState(true);
  const [payAnotherAmount, setPayAnotherAmount] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState(true);
  const [amount, setAmount] = useState(balance);
  const [loading, setLoading] = useState(false);

  const formConfig = {
    defaultValues: {
      first_name: "",
      last_name: "",
      number: "",
      exp_month: "1",
      exp_year: "2022",
      type: "amex",
    },
  };

  const {
    register,
    // control,
    handleSubmit,
    // setValue,
    formState: { errors },
    // reset,
  } = useForm(formConfig);

  const handleSubmitSave = (dataForm) => {
    if (paymentMethod) {
      setLoading(true);
      let data = [
        {
          amount,
        },
        account.billing_api_id,
      ];
      petitionPost("payments_creditcard-onfile", data)
        .then((result) => {
          sendToastSuccess();
          setStep("orders");
        })
        .catch((err) => {
          console.log(err);
          sendToastError();
        })
        .finally(() => setLoading(false));
    } else {
      setLoading(true);
      let data = [
        {
          amount,
          cc_first_name: dataForm.first_name,
          cc_last_name: dataForm.last_name,
          cc_num: dataForm.number,
          cc_exp_month: dataForm.exp_month,
          cc_exp_year: dataForm.exp_year,
          cc_type: dataForm.type,
        },
        account.billing_api_id,
      ];

      petitionPost("payments_creditcard", data)
        .then(({ data: { result } }) => {
          sendToastSuccess();
          setStep("orders");
        })
        .catch((err) => sendToastError())
        .finally(() => setLoading(false));
    }
  };

  useEffect(() => {
    setPaymentMethod(thereIsCreditCard)
  }, [thereIsCreditCard])

  return (
    <div className="col-lg-9 col-12">
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <div className="card">
        <div className="card-body " style={{ padding: "1.25rem" }}>
          <div className="card-title">
            <strong className="text-make-payment">Payment Amount </strong>
            <div className="d-flex justify-content-between mt-3">
              <FormGroup check="true">
                <div className="d-flex align-items-center">
                  <input
                    type="radio"
                    name="radio2"
                    checked={checkCurrentBalance}
                    id="currentBalance"
                    onChange={(e) => {
                      setCurrentBalance(true);
                      setcheckCurrentBalance(!checkCurrentBalance);
                      setPayAnotherAmount(!payAnotherAmount);
                      setAmount(balance);
                    }}
                    disabled={balance === "0" ? true : false}
                  />
                  <p className="px-3 m-0 text-make-payment">Current Balance</p>
                </div>
              </FormGroup>

              <h3>$ {Number(amount).toLocaleString()}</h3>
            </div>
            <div className="d-flex justify-content-between mb-5">
              <FormGroup check="true">
                <div className="d-flex align-items-center">
                  <input
                    type="radio"
                    name="radio2"
                    id="payAnotherAmount"
                    checked={payAnotherAmount}
                    onChange={() => {
                      setCurrentBalance(false);
                      setcheckCurrentBalance(!checkCurrentBalance);
                      setPayAnotherAmount(!payAnotherAmount);
                      setAmount(balance);
                    }}
                    disabled={balance === "0" ? true : false}
                  />
                  <p className="px-3 m-0 text-make-payment">
                    Pay Another Amount
                  </p>
                </div>
              </FormGroup>
              <input
                type="number"
                value={currentBalance ? "" : amount}
                onChange={(e) => setAmount(e.target.value)}
                style={{
                  maxWidth: "20em",
                }}
                disabled={currentBalance}
              />
            </div>
            <strong className="mt-5 text-make-payment">Payment Method </strong>
            {thereIsCreditCard && (
              <div className="d-flex justify-content-between mt-3">
                <FormGroup check="true">
                  <div className="d-flex align-items-center">
                    <input
                      type="radio"
                      name="radio3"
                      id="existingCard"
                      checked={paymentMethod}
                      onChange={(e) => setPaymentMethod(true)}
                    />
                    <p className="px-3 m-0 text-make-payment">
                      Use Existing Card
                    </p>
                  </div>
                </FormGroup>
              </div>
            )}
            <div className="d-flex justify-content-between">
              <FormGroup check="true">
                <div className="d-flex align-items-center">
                  <input
                    type="radio"
                    name="radio4"
                    id="newCreditCard"
                    checked={!paymentMethod}
                    onChange={(e) => setPaymentMethod(false)}
                  />
                  <p className="px-3 m-0 text-make-payment">
                    Use New Credit/Debit Card
                  </p>
                </div>
              </FormGroup>
            </div>
            {!paymentMethod && (
              <div>
                <div className="row mt-3">
                  <div className="form-group mb-3 ">
                    <label className="form-label text-make-payment">
                      First name
                    </label>
                    <input
                      {...register("first_name", { required: true })}
                      type="text"
                      placeholder=""
                      className={`form-control ${
                        errors.first_name && "form-control-error"
                      } `}
                    />
                  </div>
                  <div className="form-group mb-3 ">
                    <label className="form-label text-make-payment">
                      Last name
                    </label>
                    <input
                      {...register("last_name", { required: true })}
                      type="text"
                      placeholder=""
                      className={`form-control ${
                        errors.last_name && "form-control-error"
                      } `}
                    />
                  </div>
                </div>
                <div className="form-group mb-3">
                  <label className="form-label text-make-payment">
                    Card Number
                  </label>
                  <input
                    {...register("number", { required: true })}
                    type="text"
                    placeholder=""
                    className={`form-control ${
                      errors.number && "form-control-error"
                    } `}
                    maxLength={16}
                  />
                </div>
                <div className="form-group mb-3 col-mb-4">
                  <label className="form-label text-make-payment">
                    Expiration
                  </label>
                  <select
                    className={`form-control ${
                      errors.exp_month && "form-control-error"
                    } `}
                    {...register("exp_month", { required: true })}
                  >
                    <option value="1"> 1 </option>
                    <option value="2"> 2 </option>
                    <option value="3"> 3 </option>
                    <option value="4"> 4 </option>
                    <option value="5"> 5 </option>
                    <option value="6"> 6 </option>
                    <option value="7"> 7 </option>
                    <option value="8"> 8 </option>
                    <option value="9"> 9 </option>
                    <option value="10"> 10 </option>
                    <option value="11"> 11 </option>
                    <option value="12"> 12 </option>
                  </select>
                </div>
                <div className="form-group mb-3 col-mb-4">
                  <label className="form-label text-make-payment">Year</label>
                  <select
                    className={`form-control ${
                      errors.exp_year && "form-control-error"
                    } `}
                    {...register("exp_year", { required: true })}
                  >
                    <option value="2022"> 2022 </option>
                    <option value="2023"> 2023 </option>
                    <option value="2024"> 2024 </option>
                    <option value="2025"> 2025 </option>
                    <option value="2026"> 2026 </option>
                    <option value="2027"> 2027 </option>
                    <option value="2028"> 2028 </option>
                    <option value="2029"> 2029 </option>
                    <option value="2030"> 2030 </option>
                    <option value="2031"> 2031 </option>
                    <option value="2032"> 2032 </option>
                  </select>
                </div>
                <div className="form-group mb-3 col-mb-4">
                  <label className="form-label text-make-payment">Type</label>
                  <select
                    className={`form-control ${
                      errors.type && "form-control-error"
                    } `}
                    {...register("type", { required: true })}
                  >
                    <option value="amex"> Amex </option>
                    <option value="visa"> Visa </option>
                    <option value="mastercard"> Mastercard </option>
                    <option value="discover"> Discover </option>
                  </select>
                </div>
              </div>
            )}
          </div>
          <div className="col-12 text-end">
            <button
              onClick={handleSubmit(handleSubmitSave)}
              disabled={loading}
              className="btn btn-primary mx-3"
              type="submit"
            >
              {loading && (
                <Spinner
                  style={{ marginRight: "0.5em" }}
                  as="span"
                  animation="grow"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              )}
              Pay Now
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    profile: state,
  };
};

export default withRouter(connect(mapStateToProps)(Orders));
