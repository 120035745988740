import React, { useEffect, useState } from "react";
import { sendToastError } from "../../../../utils/toastAlert";
import { Button } from "react-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PageTitle from "../../../layouts/PageTitle";
import Orders from "./Orders";
import Statements from "./Statements";
import AddCardModal from "./AddCardModal";
import MakePayment from "./makePayment";
import petitionGet from "../../../../services/petitionGet";

const ProductOrder = (props) => {
  const [modal, setModal] = useState(false);
  const [step, setStep] = useState("orders");
  const [dataAccount, setDataAccount] = useState("");
  const [reload, setReload] = useState(true);
  const [cardData, setCardData] = useState({});
  const [loadingModal, setLoadingModal] = useState(false);
  const [checked, setChecked] = useState(false);
  const [thereIsCreditCard, setThereIsCreditCard] = useState(false)

  let { account } = props.profile.auth.auth;

  useEffect(() => {
    if (reload) {
      setReload(false);
      setCardData({});
      setLoadingModal(true);
      petitionGet("account", account.billing_api_id)
        .then(({ data: { result } }) => {
          if (result?.credit_card_info?.last_4) {
            setThereIsCreditCard(true)
          }

          setChecked(result.automatic_payments);
          console.log(result);
          setCardData(result);
          setLoadingModal(false);
        })
        .catch((error) => sendToastError());
    }
  }, [reload]);

  useEffect(() => {
    petitionGet("account", account.billing_api_id)
      .then(({ data: { result } }) => setDataAccount(result))
      .catch((err) => sendToastError());
  }, [step]);

  return (
    <div className="h-80">
      <PageTitle activeMenu="Payments" motherMenu="Billing" />

      <AddCardModal
        setModal={setModal}
        modal={modal}
        setReload={setReload}
        cardData={cardData}
        loading={loadingModal}
        setLoading={setLoadingModal}
        checked={checked}
        setChecked={setChecked}
        setThereIsCreditCard={setThereIsCreditCard}
      />

      <div className="row">
        {step === "orders" && <Orders />}
        {step === "statements" && <Statements />}
        {step === "makePayment" && (
          <MakePayment setStep={setStep} balance={dataAccount.balance} thereIsCreditCard={thereIsCreditCard} />
        )}

        <div className="col-xl-3">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="profile-statistics">
                    <div className="text-center">
                      <div className="row">
                        <div className="col">
                          <h3 className="m-b-0">
                            ${Number(dataAccount.balance).toLocaleString()}
                          </h3>
                          <span>Current Balance Due</span>
                        </div>
                      </div>
                      <div className="mt-4">
                        <Button
                          as="a"
                          href="#"
                          className="btn btn-primary mb-1 ms-1 col-12 mt-4"
                          onClick={() => setStep("makePayment")}
                        >
                          Make Payment
                        </Button>
                        <Button
                          as="a"
                          href="#"
                          className="btn btn-primary mb-1 ms-1 col-12 mt-4"
                          onClick={() => setStep("orders")}
                        >
                          Orders
                        </Button>
                        <Button
                          as="a"
                          href="#"
                          className="btn btn-primary mb-1 ms-1 col-12 mt-4"
                          onClick={() => setStep("statements")}
                        >
                          View Statements
                        </Button>
                        <Button
                          as="a"
                          href="#"
                          className="btn btn-primary mb-1 ms-1 col-12 mt-4"
                          onClick={() => setModal(true)}
                        >
                          Automatic Payments Settings
                        </Button>
                      </div>
                    </div>
                    {/* send Modal */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    profile: state,
  };
};

export default withRouter(connect(mapStateToProps)(ProductOrder));
