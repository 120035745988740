import { lazy, Suspense, useEffect } from "react";
import {
  ThemeProvider,
  createTheme,
  makeStyles,
} from "@material-ui/core/styles";

/// Components
import Index from "./jsx";
import { connect, useDispatch } from "react-redux";
import { Route, Switch, withRouter } from "react-router-dom";
// action
import { checkAutoLogin, saveTokenInLocalStorage } from "./services/AuthService";
import { isAuthenticated } from "./store/selectors/AuthSelectors";
import { logout } from "./store/actions/AuthActions";
import ChangePassword from "./jsx/pages/Authentication/ChangePassword";
/// Style
import "./vendor/bootstrap-select/dist/css/bootstrap-select.min.css";
import "./css/style.css";

import config from "./config.json";
import petitionGet from "./services/petitionGet";
import logoBlack from "./images/logoBlack.png";

// const Impersonate = lazy(() => import("./jsx/pages/impersonate.js"));
const SignUp = lazy(() => import("./jsx/pages/Registration"));
const ForgotPassword = lazy(() => import("./jsx/pages/ForgotPassword"));
const Login = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(import("./jsx/pages/Login")), 500);
  });
});

const theme = createTheme();

const useStyles = makeStyles((theme) => {
  root: {
    // some CSS that accesses the theme
  }
});

function App(props) {
  const classes = useStyles();

  const params = new URL(document.location).searchParams;
  const access_token = params.get("access_token");

  const loginWithToken = async (token, path) => {
    await petitionGet("refresh", token)
      .then(({ data: result }) => {
        saveTokenInLocalStorage(result.result);
        window.location.href = window.location.origin + path
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const dispatch = useDispatch();
  useEffect(() => {
    const pathname = window.location.pathname;
    // console.log(pathname);
    if (pathname !== "/page-change-password" && !access_token) {
      checkAutoLogin(dispatch, props.history);
    }
  }, [dispatch, props.history]);

  useEffect(() => {
    var logoutVar = params.get("logout");
    const path = window.location.pathname;

    if (access_token) {
      loginWithToken(access_token, path)
    }

    if (logoutVar === "true" && !access_token) dispatch(logout(props.history));
  }, []);

  useEffect(() => {
    if (window.location.pathname === "/login" && props.isAuthenticated && !access_token) {
      const params = new URL(document.location).searchParams;
      const token = params.get("token");
      const redirect = params.get("redirect");

      if (!redirect) {
        if (token)
          // window.location.href = `https://app${
          //   config.prod ? "" : ".dev"
          // }.voice360.app/login?token${token}`;
          props.history.push("/apps");
        else props.history.push("/apps");
      } else {
        const token = JSON.parse(localStorage.getItem("userDetails")).token;
        window.location.href = `https://app${
          config.prod ? "" : ".dev"
        }.voice360.app/login?token=${token}`;
      }
    }
  }, [props.isAuthenticated]);

  let routes = (
    <Switch>
      {/* <Route path="/impersonate" component={Impersonate}/> */}
      <Route path="/page-change-password" component={ChangePassword} />
      <Route path="/login" component={Login} />
      <Route path="/page-register" component={SignUp} />
      <Route path="/page-forgot-password" component={ForgotPassword} />
    </Switch>
  );

  if (props.isAuthenticated && !access_token) {
    return (
      <>
        <ThemeProvider theme={theme}>
          <Suspense
            fallback={
              <div id="preloader">
                <div className="sk-three-bounce">
                  <div className="sk-child sk-bounce1"></div>
                  <div className="sk-child sk-bounce2"></div>
                  <div className="sk-child sk-bounce3"></div>
                </div>
              </div>
            }
          >
            <Index />
          </Suspense>
        </ThemeProvider>
      </>
    );
  } else if (!props.isAuthenticated && !access_token) {
    return (
      <div className="vh-100">
        <Suspense
          fallback={
            <div id="preloader">
              <div className="sk-three-bounce">
                <div className="sk-child sk-bounce1"></div>
                <div className="sk-child sk-bounce2"></div>
                <div className="sk-child sk-bounce3"></div>
              </div>
            </div>
          }
        >
          {routes}
        </Suspense>
      </div>
    );
  } else if (access_token) {
    return (
      <>
    <div className="authincation d-flex flex-column flex-lg-row flex-column-fluid">
      <div className="login-aside text-center  d-flex flex-column flex-row-auto">
        <div className="d-flex flex-column-auto flex-column pt-lg-40 pt-15">
          <div className="text-center mb-4 pt-5">
            <img src={logoBlack} alt="" style={{ width: "200px" }} />
          </div>
          <h3 className="mb-2">Welcome back!</h3>
        </div>
        {/* <div
          className="aside-image"
          style={{backgroundImage: "url(" + loginbg + ")"}}
        ></div> */}
      </div>
      <div className="container flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-7 mx-auto">
        <div className="d-flex justify-content-center h-100 align-items-center">
          <div className="authincation-content style-2">
            <div className="row no-gutters">
              <div className="col-xl-12 tab-content">
                <div id="sign-in" className="auth-form   form-validation">
                  <h3 className="text-center mb-4 text-black">
                    Sit tight, we are logging you in...
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: isAuthenticated(state),
  };
};

export default withRouter(connect(mapStateToProps)(App));
